import {
	BreadcrumbItem,
	Breadcrumbs,
	Heading,
	Link,
	Picture,
	Tab,
	TabList,
	TabPanel,
	Tabs,
	Container,
	Page,
	Section,
} from '@troon/ui';
import { Title } from '@solidjs/meta';
import { For, Suspense, Show } from 'solid-js';
import { createAsync, useParams } from '@solidjs/router';
import { twJoin } from '@troon/tailwind-preset/merge';
import { Error404, gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { FacilitiesByGrouping } from '../../../components/facility/by-grouping';
import { NotFoundContent } from '../../../partials/404';

export default function CourseDirectory() {
	const params = useParams();

	const data = createAsync(() => getFacilities({ macroRegionIds: [params.region ? params.region : 'americas'] }), {
		deferStream: true,
	});

	return (
		<Suspense>
			<Show when={data.latest?.regions?.find((r) => r.id === params.region)} fallback={<NotFoundContent />}>
				<Container>
					<Title>Troon Golf Courses by Region | Troon</Title>
					<div class="pt-4">
						<Breadcrumbs>
							<BreadcrumbItem href="/courses">Explore courses</BreadcrumbItem>
							<BreadcrumbItem href="/courses/directory">Full list</BreadcrumbItem>
						</Breadcrumbs>
					</div>

					<Page class="relative">
						<For each={data()?.featured?.facilities}>
							{(feat, index) => (
								<Picture
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class={twJoin(
										'absolute -z-0 hidden aspect-[3/4] w-[300px] rounded xl:block',
										index() % 2 ? '-right-36 top-12 -rotate-6' : '-bottom-12 -left-36 rotate-6',
									)}
									alt=""
									src={feat.metadata?.hero?.url}
									width={300}
									height={400}
									sizes="300px"
									loading="lazy"
								/>
							)}
						</For>
						<Section class="z-0">
							<Heading as="h1">
								Explore<span class="sr-only"> Troon golf courses</span> by Region
							</Heading>

							<Suspense>
								<Tabs defaultValue={params.region ? params.region : undefined} class="flex flex-col gap-10">
									<TabList aria-label="Course regions">
										<For each={data.latest?.regions}>
											{(region) => (
												<Tab value={region.id} as={Link} href={`/courses/directory/${region.id}`} replace={true}>
													{region.name}
												</Tab>
											)}
										</For>
									</TabList>
									<For each={data.latest?.regions}>
										{(region) => (
											<TabPanel value={region.id}>
												<FacilitiesByGrouping
													facilities={data()?.facilities.facilities ?? []}
													grouping={region.id === 'americas' ? 'state' : 'country'}
												/>
											</TabPanel>
										)}
									</For>
								</Tabs>
							</Suspense>
						</Section>
					</Page>
				</Container>
			</Show>
		</Suspense>
	);
}

const query = gql(`query courseDirectory($macroRegionIds: [String!]!) {
	regions: macroRegions {
		id
		name
	}
	facilities: facilitiesV3(macroRegionIds: $macroRegionIds, sortBy: name) {
		facilities {
			...FacilityByState
		}
	}
	featured: facilitiesV3(macroRegionIds: $macroRegionIds, limit: 2) {
		facilities {
			metadata {
	 			hero {
		 			url
		 		}
			}
		}
	}
}`);

const getFacilities = cachedQuery(query, {
	onError: (error) => {
		if (error.graphQLErrors[0]?.message.toLowerCase().includes('not found')) {
			throw new Error404(error.graphQLErrors[0]);
		}
	},
});
